export const insertScript = (src: string) => {
	const trackingScript = document.createElement("script")

	trackingScript.async = true
	trackingScript.src = src

	const loaded = new Promise<void>((res) => {
		const cb = () => {
			res()

			trackingScript.removeEventListener("load", cb)
		}

		trackingScript.addEventListener("load", cb)
	})

	const firstScript = document.getElementsByTagName("script")[0]
	if (firstScript && firstScript.parentNode) {
		firstScript.parentNode.insertBefore(trackingScript, firstScript)
	} else {
		document.body.append(trackingScript)
	}

	return loaded
}
