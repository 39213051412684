export type ClassName =
	| string
	| undefined
	| null
	| false
	| Record<string, boolean>
export const classNames = (...args: ClassName[]) => {
	const classes = []

	for (const item of args) {
		if (!item) {
			continue
		}

		if (typeof item === "string") {
			classes.push(item)

			continue
		}

		for (const key in item) {
			if (!item[key]) {
				continue
			}

			classes.push(item[key])
		}
	}

	return classes.join(" ")
}
