import React from "react"

import Styles from "./LoadingIndicator.module.css"

import { classNames } from "@/common/helper/classNames"

const LoadingIndicator: React.FC<{
	className?: string
}> = ({ className = "bg-primary-default" }) => {
	return <div className={classNames(Styles.loading, className)} />
}

export const MediumLoadingIndicator: React.FC<
	React.PropsWithChildren<{
		className?: string
	}>
> = ({ className = "", children, ...props }) => {
	return (
		<div className={Styles.wrap}>
			<div className={`${Styles.constrain} ${className ?? ""}`}>
				<LoadingIndicator {...props} />
			</div>
			{!children && (
				<span className={Styles.aria} role="alert">
					Loading. Please wait.
				</span>
			)}
			{children && <p className={Styles.content}>{children}</p>}
		</div>
	)
}

export default LoadingIndicator
