import { onCookieFirstServiceUpdate } from "./cookieFirst"
import { loadMatomoAndTrack } from "./matomo"

export const initTracking = () => {
	onCookieFirstServiceUpdate((services) => {
		if (services.includes("matomo")) {
			loadMatomoAndTrack()
		}
	})
}
