import { insertScript } from "./trackingHelper"
import { queryParams, referrers } from "./matomoExclude.json"

declare global {
	interface Window {
		_paq?: unknown[][]
		_mtm?: unknown[][]
	}
}

type DimensionType<T extends string> = `dimension${T}`
type MatomoDimension<T extends string> = Record<DimensionType<T>, string>

const MatomoApi = {
	setTrackerUrl: (url: string) => [url],
	setCustomUrl: (url: string) => [url],
	setSiteId: (id: string) => [id],
	setReferrerUrl: (referrer: string) => [referrer],
	setDocumentTitle: (title: string) => [title],
	trackPageView: () => [],
	enableLinkTracking: () => [],
	trackAllContentImpressions: () => [],
	trackEvent: <D extends string>(
		category: string,
		action: string,
		name?: string,
		value?: string,
		dimension?: MatomoDimension<D>
	) => [category, action, name, value, dimension],
}
type TMatomoApi = typeof MatomoApi & Record<string, (...any: any) => typeof any>

let active = false

export const isMatomoActive = () => {
	if (import.meta.env.DEV) {
		return false
	}

	return active
}

export const isMatomoLoaded = () => {
	return !!window._paq
}

export const isMatomoTagLoaded = () => {
	return !!window._mtm
}

const _loadMatomoTag = () => {
	if (!isMatomoActive() || isMatomoTagLoaded()) {
		return
	}

	const _mtm = (window._mtm = window._mtm || [])

	_mtm.push({
		"mtm.startTime": new Date().getTime(),
		event: "mtm.Start",
	} as any)

	insertScript("https://data.whu.edu/js/container_nBaKtrG6.js")
}

const _loadMatomo = () => {
	if (!isMatomoActive() || isMatomoLoaded()) {
		return
	}

	const endpoint = "https://data.whu.edu/"
	matomoApi("setTrackerUrl", endpoint + "matomo.php")
	matomoApi("setSiteId", "16")

	/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
	matomoApi("setExcludedQueryParams", queryParams)
	matomoApi("setExcludedReferrers", referrers)
	matomoApi("trackPageView")
	matomoApi("enableLinkTracking")

	insertScript(endpoint + "matomo.js")
}

export const loadMatomo = () => {
	active = true

	_loadMatomo()
	_loadMatomoTag()
}

export const loadMatomoAndTrack = () => {
	loadMatomo()
	sendPageView()
}

export const matomoApi = <TKey extends keyof TMatomoApi>(
	key: TKey,
	...arg: Parameters<TMatomoApi[TKey]>
) => {
	if (!active) {
		return
	}

	//@ts-ignore
	const api = key in MatomoApi ? MatomoApi[key] : (...a: any) => a
	//@ts-ignore
	const mapped = api(...arg)

	const _paq = (window._paq = window._paq || [])
	_paq.push([key, ...mapped])
}

export const sendMatomoEvent = (
	...args: Parameters<TMatomoApi["trackEvent"]>
) => {
	return matomoApi("trackEvent", ...args)
}

const buildUrl = (l: Location) => l.pathname + l.search + l.hash

const sendPageView = () => {
	const title = document.title

	matomoApi("setCustomUrl", buildUrl(window.location))
	matomoApi("setDocumentTitle", title)
	matomoApi("trackPageView")
	matomoApi("enableLinkTracking")
	matomoApi("trackAllContentImpressions")
}

export const trackPageView = () => {
	if (!isMatomoActive() || !isMatomoLoaded()) {
		return
	}

	// Add delay to ensure react-helmet can set the page title
	const delay = Math.max(32, 0)
	setTimeout(() => {
		sendPageView()
	}, delay)
}
